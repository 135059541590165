<script setup>
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

const innerValue = ref(false);
const uniqid = getCurrentInstance().uid;

watchEffect(() => (innerValue.value = props.modelValue));
watchEffect(() => emit("update:modelValue", innerValue.value));
</script>
<template>
  <client-only>
    <div class="toggleWrapper">
      <input
        :id="uniqid"
        type="checkbox"
        class="mobileToggle"
        v-model="innerValue"
        :checked="innerValue"
      />
      <label :for="uniqid"></label>
    </div>
  </client-only>
</template>

<style scoped>
.toggleWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

input {
  /* min-width: 100%;
  min-height: 100%; */
  cursor: pointer;
  width: 100%;
  height: 100%;
}

label {
  pointer-events: none;
}

.toggleWrapper input.mobileToggle {
  opacity: 0;
  position: absolute;
}

.toggleWrapper input.mobileToggle + label {
  position: relative;
  display: inline-block;
  user-select: none;
  -moz-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-tap-highlight-color: transparent;
  height: 16px;
  width: 40px;
  /* outline: 1px solid var(--switch-border); */
  border-radius: 60px;
  background-color: #eaeef9;
}

.toggleWrapper input.mobileToggle + label:before {
  content: "";
  position: absolute;
  display: block;
  -moz-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -o-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  -webkit-transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
  height: 28px;
  width: 42px;
  top: -1px;
  left: -1px;
  border-radius: 30px;
}

.toggleWrapper input.mobileToggle + label:after {
  content: "";
  position: absolute;
  display: block;
  /* box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0px 0 rgba(0, 0, 0, 0.04),
    0 4px 9px rgba(0, 0, 0, 0.13), 0 3px 3px rgba(0, 0, 0, 0.05); */
  -moz-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -o-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  -webkit-transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  transition: 0.35s cubic-bezier(0.54, 1.6, 0.5, 1);
  background: #bbc2d4;
  height: 23px;
  width: 23px;
  top: -4px;
  left: 0px;
  border-radius: 60px;
}

.toggleWrapper input.mobileToggle:checked + label:before {
  background: var(--switch);
  -moz-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -o-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  -webkit-transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  transition: width 0.2s cubic-bezier(0, 0, 0, 0.1);
  background-color: var(--basis-sub-color);
}

.toggleWrapper input.mobileToggle:checked + label:after {
  left: 21px;
  background-color: var(--root-green);
  filter: drop-shadow(2px 8px 12px rgba(0, 191, 164, 0.25));
}
</style>
